<template>
  <div class="h-full w-screen">
    <PageSection
      reduced
      class="h-64"
    >
      <SectionText>
        If you are brand new to training, we understand how daunting or nerve wrecking it may be entering into a gym for the first time. Here at UNIT 3, we aim to make that initial step as easy and as comfortable as possible from the very first moment you walk in the door.
      </SectionText>
      <SectionText>
        Every UNIT 3 class is fully coached and supervised, making all sessions completely accessible for beginners.
      </SectionText>
      <button
        class="w-auto mt-4"
        @click="
          toggleModal({
            modalName: 'contact',
            subject: $route.meta.title,
            show: true,
          })
        "
      >
        <div
          class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
          :aria-label="`Contact us about Beginning training at UNIT 3`"
        >
          Contact us about Beginning training at UNIT 3 
        </div>
      </button>
    </PageSection>
    <LoadingState v-if="getTestimonialsIsLoading" />
    <ErrorState
      v-else-if="getTestimonialsIsError"
      :error="getTestimonialsError"
      :retry="fetchTestimonials"
    />

    <PageSection
      v-else
      title="Client testimonials"
      clas="flex flex-col "
    >
      <div
        :class="[
          'grid grid-cols-6 gap-12 pt-8 ',
        ]"
      >
        <div
          v-for="(athlete, index) in getTestimonials"
          :key="index"
          class="md:col-span-3 lg:col-span-3 col-span-6 flex flex-grow"
        >
          <Card
            bordered
            :background="'bg-opacity-75 bg-secondary-200'"
          >
            <template v-slot:subtitle>
              <p
                class="text-primary font-base leading-normal text-center"
                :class="[
                  'text-base',
                ]"
              >
                {{ athlete.athlete }}
              </p>
            </template>
            <template v-slot:title>
              <div
                class="flex flex-col gap-y-2 items-center justify-center mb-6"
              >
                <div
                  v-if="athlete.achievements"
                >
                  <p
                    v-for="achievement in athlete.achievements"
                    :key="achievement"
                    class="italic text-primary text-xs text-center"
                  >
                    {{ achievement }}
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <div class="border-l pl-4 border-secondary-DEFAULT">
                <BlogBlocks :content="athlete.comment" />
              </div>
            </template>
          </Card>
        </div>
      </div>
    </PageSection>
    <LoadingState v-if="getImagesIsLoading" />
    <ErrorState
      v-else-if="getImagesIsError"
      :error="getImagesError"
      :retry="fetchImages"
    />
    <Gallery
      v-else-if="getImages && getImages.length"
      :images="getImages"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ErrorState, LoadingState, PageSection, SectionText, Card, Gallery } from '@/pages/shared/index';
import testimonialStore from '@/infrastructure/store/modules/testimonials/';
import imagesStore from '@/infrastructure/store/modules/images/';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'BeginnersPage',
  components: {
    ErrorState,
    LoadingState,
    PageSection,
    SectionText,
    Card,
    BlogBlocks,
    Gallery
  },
  computed: {
    ...mapGetters('images', [ 'getImages', 'getImagesIsError','getImagesError', 'getImagesIsLoading'  ]),
    ...mapGetters('testimonial', [ 'getTestimonials', 'getTestimonialsIsError','getTestimonialsError', 'getTestimonialsIsLoading'  ])
  },
  async created() {
    if(!this.$store.hasModule('testimonial')) this.$store.registerModule('testimonial', testimonialStore);
    await this.fetchTestimonials('beginners');
    if(!this.$store.hasModule('images')) this.$store.registerModule('images', imagesStore);
    await this.fetchImages('beginners');
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('testimonial', [ 'fetchTestimonials' ]),
    ...mapActions('images', [ 'fetchImages' ])
  }
};
</script>
